.form {
    display: flex;
    flex-direction: column;
}

.field label, .field {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 5px 10px;
    color: whitesmoke;
}

.inputFilter input {
    background: rgba(245, 245, 245, 0.7);
    margin: 5px 0;
    padding: 10px;
    color: whitesmoke;
    border-radius: 25px;
    border: none;
}

.inputMinMax {
    width: 45%;
    margin-right: 5px;
}

.from_to_ctn {
    padding: 5px 10px;
}