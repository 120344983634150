.ctn{
    min-height: 100vh;
    background-color: #dadada;
    overflow: hidden;
  
  }
  .divScroll {
    margin-left: 20%;
    /* min-height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  