.paginacion{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#li-first,#li-previous,#li-next,#li-Last{
    list-style: none;
    margin: 0.5rem;
    cursor: pointer;
    color: var(--blue);
}

.number-page{
    list-style: none;
    margin: 0.5rem;
}

.number-page-selected{
    list-style: none;
    margin: 0.5rem;
    cursor: pointer;
    color: var(--blue);
}