.ctn {
  min-width: 300px;
  margin: 15px auto;
  background-color: var(--white);
  text-align: center;
  padding: 15px;
  box-shadow: 2px 4px 6px 3px var(--blackShadow);
  border-radius: 20px;
}
.button {
  border-radius: 20px;
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white);
  padding: 10px;
  width: 97%;
  cursor: pointer;
  box-shadow: 1px 1px 4px 2px var(--blackShadow);
}
.text {
  border-top: 2px solid var(--blackShadow);
  padding: 15px 10px;
  margin-top: 5px;
}
