.ctn{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  background-color: var(--white);
  border: 2px solid var(--blue);
  border-radius: 20px;
  box-shadow: 2px 4px 6px 3px var(--blackShadow);
  margin: 4% 0;
  padding: 2%;
}

.ctn .img{
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 5%;
  display : flex;
}


.ctn .premium {
  color: goldenrod;
}

.ctn .propInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.ctn .propInfo .details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 2% auto;
}

.ctn .propInfo .details .icon{
  margin-left: 0.5em;
}

.ctn .propInfo .general {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.ctn .propInfo .general .title_price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 3% 0;
  font-size: 1rem;
}

.ctn a {
  text-decoration: none;
  color: var(--blue);
  transition: all 0.25s;
}

.ctn a:hover {
  color: var(--dark);
  font-size: 1.01rem;
}

.ctn a h3 {
  margin: 0 20px 0 0;
}

.details p {
  color:  var(--dark);
  font-size: 20px;
}

.general p {
  color:  var(--dark);
  font-size: 18px;
}
