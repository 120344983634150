.logout {
  width: 150px;
  height: 30px;
  font-family: 'Poppins';
  color: var(--blue);
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 10%;
  border-radius: 10px;
  transition: all .3s ease-in-out;
  border: 2px solid var(--blue);
  background-color: none;
}
.logout:hover {
    border: none;
    transition: all .3s ease-in-out;
    background-color: var(--dark);
    color: var(--white);
}