.filter {
    border-radius: 20px;
    background: var(--white);
    border: 2px solid var(--blue);
    box-shadow: 0px 3px 6px 0px var(--blackShadow);
    padding: 2em;
    height: max-content;
    width: max-content;
}

.filter .form { 
    display: 'flex';
    flex-direction: 'column';
    justify-content: center;
    align-items: center;
}

.filter .form .field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    color: var(--blue);
    margin: 10% 0;
}

.from_to {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 0.8em;
}

.filter .form .field select {
    background-color: var(--white);
    height: 2.2em;
    width: 10em;
    border-radius: 12px;
    border: 2px solid var(--blue);
    color: var(--blue);
    outline:0;
    cursor: pointer;
    font-family: 'Poppins';
}

.inputMinMax {
    width: 22%;
}

.form .field input{
   cursor: pointer;
   height: 100%;
   outline:0;
   font-family: 'Poppins';
   font-size: 0.9em;
   border-radius: 4px;
   border: 2px solid var(--blue);
   color: var(--blue);
   text-align: center;
   background-color: var(--white);
}

.closeIcon{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 1.5rem;
    color: var(--blue) !important;
    cursor: pointer;
}

.closeIcon:hover{
    transform: translate(-0.5%);
    transition: 0.2s;
}

.tit_facilities {
    font-weight: 600;
    cursor: pointer;
}

.tit_facilities:hover {
    transition: all .3s ease-in-out;
    text-shadow: 2px 2px 0px #bdbdbd83;
}

.facilities, .facilities input {
    color: var(--blue);
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.noFacilities{
    display: none;
}