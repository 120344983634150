.slider {
  position: relative;
/*   width: 100%; */
  height: 380px;
  margin: 0 auto;
/*   border: 1px solid red; */
}

.slideActive {
  opacity: 1;
}

.rightArrow {
  position: absolute;
  top: 160px;
  right: 32px;
  font-size: 4rem;
  color: var(--blue);
  z-index: 10;
  cursor: pointer;
  user-select: none;
  text-shadow: 2px 2px 0px #bdbdbd55;
}

.leftArrow {
  position: absolute;
  top: 160px;
  left: 32px;
  font-size: 4rem;
  color: var(--blue);;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  text-shadow: 2px 2px 0px #bdbdbd55;
}

.leftArrow,
.rightArrow {
  transition: all 0.5s ease;
}

.leftArrow:hover,
.rightArrow:hover {
  color: var(--blue);
}

.image {
  width: 100%;
  position: absolute;
  height: 350px;
  object-fit: contain;
}

.slide {
/*   width: 620px; */
  opacity: 0;
/*   transition-duration: 1s ease; */
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.025);
}
