.ctn {
  margin: 3% 6%;
}

.ctn h3, .ctn h1 {
  font-family: 'Poppins';
}

.steps-content {
    min-height: 200px;
    max-height: fit-content;
    margin: 1em;
    background-color: #d4c8c846;
    border: 1px dashed #e9e9e9;
    border-radius: 25px;
}
  
.steps-action {
  margin: 24px auto;
}

.ant-steps-item-title, .ant-btn{
  font-family: 'Poppins' !important;
  font-size: 1rem !important;
}

.ant-steps-item-tail, .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: var(--blue) !important;
  border: none;
}
.ant-btn {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--white);
  height: 2.2em !important;
  width: 8em;
  border-radius: 15px!important;
  border: 2px solid var(--blue) !important;
  color: var(--blue) !important;
}

.ant-btn-primary{
  border: none !important;
  color: var(--white) !important;
  background: var(--blue) !important;
  transition: all 0.3s ease-in-out !important;
}
.ant-btn:hover {
  border: none !important;
  color: var(--white) !important;
  background: var(--dark) !important;
  box-shadow: 1px 1px 0px #bdbdbd !important;
  transition: all 0.3s ease-in-out !important;
}