.home{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 4% 2%;
}
.home .filterArea{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 4%;
    width: 20%;
}

.home .filterArea .filterCont{ 
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    height: 60%;
    padding: 6%;
}

.home .main{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 80%;
    margin: 0 2%;
}

.home .main .search_order {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 2em;
}