.signup {
    color: var(--white);
    background-color: var(--blue);
    font-family: 'Poppins';
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    margin: 0 10%;
    border-radius: 10px;
    width: 150px;
    height: 30px;
    transition: all .3s ease-in-out;
    border: 2px solid var(--blue);
  }
  .signup:hover {
    border: none;
    transition: all .3s ease-in-out;
    background-color: var(--dark);
  }