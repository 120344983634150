.ctn{
  position: absolute;
  /* background-color: blue; */
  width: 20%;
  height: 100%;
  background-color: var(--blue);
  color: var(--white);
  padding: 1%;
  font-size: 1em;
}
.ctnUser {
  background-color: var(--blue);
}
.ctnFixed {
  position: fixed;
  top:0;
}
.ctn label{
  color: var(--white);
  font-size: 1em;
}
.ctn a{
  text-decoration: none;
  color: var(--black);
}
.ctn h4 {
  font-size: 1.1em;
  margin: 5% 0;
}

.ctn h3 {
  margin-top: 8%;
}
.divTitle {
  margin: 10% 0;
}
