.ctn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: var(--dark);
  height: 10em;
}

.ctn .title {
  font-size: 2em;
  text-shadow: 1px 1px 0 var(--blackShadow);
  font-weight: 500;
}

.ctn .description {
  font-size: 1.3;
  font-weight: 500;
}