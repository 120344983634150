.ctn {
  margin-top: 1%;
  background-color: var(--white);
  padding: 10px;
  position: relative;
}
.ctn label{
  cursor: pointer;
  text-decoration: none;
  color: var(--dark);
}

.elementsCtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnCtn {
  display: flex;
  width: 25%;
}

.btnBar {
  color: var(--white);
  text-decoration: none;
  font-size: 1rem;
  cursor:  pointer;
  margin: 0 10px;
  background: var(--blue);
  border-radius: 2em;
  padding: 2%;
  transition: all 0.3s ease-in-out;
  width: 45%;
  text-align: center;
  border: none;
}


.btnBar:hover {
  background: var(--dark);
  box-shadow: 1px 1px 0px #bdbdbd;
  transition: all 0.3s ease-in-out;
}