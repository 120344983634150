nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 6px 0px var(--blackShadow);
}

.link {
  color: var(--black);
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 10%;
  transition: all .3s ease-in-out;
}
.link:hover {
  transition: all .3s ease-in-out;
  text-shadow: 1px 1px 0px #bdbdbd;
}

/* .active {
  color: var(--blue);
} */

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
}

.logo {
  height: 50%;
  width: 50%;
  margin: 1%;
  color: var(--white);
}
